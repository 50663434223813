@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

.document {
  display: flex; // Включаем flexbox
  align-items: stretch; // Элементы растягиваются на всю высоту контейнера
  border-radius: 4px;
  //border: 1px solid black;
  margin: 5px;

  .img {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  span {
    color: $gray-500;
  }
}