.mod-recent {

  &-header {
    color: green;

    &-list {
      max-width: 19rem;
      .card {
        width: 18rem;
      }
    }

    &-buttons {
      max-width: 17em;

      button {
        width: 100%;
        margin: 5px 1px 5px 1px;
      }
    }
  }
}